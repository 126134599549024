<template>
  <v-container
    class="container-bg rounded elevation-3"
    fluid>
    <v-form
      ref="uom-form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col cols="12">
          <h2>จัดการ Unit of measure</h2>
        </v-col>
        <v-col
          cols="12"
          class="header-line">
          <span>
            ข้อมูล Unit of measure
          </span>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-text-field
            v-model="formData.code"
            label="รหัส"
            placeholder="CM"
            color="secondary"
            :rules="textBoxRules"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-text-field
            v-model="formData.name"
            label="ชื่อ"
            placeholder="Centimeter..."
            color="secondary"
            :rules="textBoxRules"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center align-center">
          <v-btn
            class="px-8"
            :loading="loading"
            color="success"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import UnitOfMeasuresProvider from '@/resources/UnitOfMeasuresProvider'

const UnitOfMeasuresService = new UnitOfMeasuresProvider()

export default {
  data () {
    return {
      loading: false,
      isEdit: false,
      textBoxRules: [
        (v) => !!v || 'โปรดกรอก Field นี้'
      ],
      formData: {
        code: '',
        name: ''
      }
    }
  },
  mounted () {
    if (this.$route.params?.id) {
      this.isEdit = true
      this.getItem()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async getItem () {
      try {
        this.loading = true

        const { data } = await UnitOfMeasuresService.getItemById(this.$route.params.id)

        this.formData = data
      } catch (error) {
        console.error('getItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      const validated = await this.$refs['uom-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true

        if (this.isEdit) {
          await UnitOfMeasuresService.updateItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'แก้ไข Unit of measure สำเร็จ',
            type: 'success'
          })
        } else {
          await UnitOfMeasuresService.createItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'สร้าง Unit of measure สำเร็จ',
            type: 'success'
          })
        }

        this.$router.push({ name: 'PurchasesUOM' })
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.header-line {
  position: relative;
}
.header-line span {
  font-weight: 500;
  background-color: white;
  padding-right: 8px;
  position: absolute;
  left: 12px;
  top: 0;
}
</style>

<style>
.switch-container .v-input--selection-controls {
  margin-top: 5px !important;
}
</style>
